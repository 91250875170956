import React from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Modal } from "react-bootstrap";
import moment from "moment";
import Swal from "sweetalert2";
import { uuid } from "uuidv4";

import Utils from "../../../lib/utils";

import ProcessingModal from "../modals/processingModal";
import FilterDocuments from "./filter";
import RolAccess from "../../common/rolAccess";

import * as actions from "./actions";
import * as commonActions from "../../common/actions";
import * as documentQueueDefinitionsActions from "./../settings/documentQueueDefinitions/actions";
import * as loginActions from "../../auth/login/actions";
import "./Documents.css";
import { CF_THIRD_PARTY_DATA_ENTRY_ID, CF_THIRD_PARTY_DATA_ENTRY_NAME, NOT_CAPTURED } from "../../../lib/appConstants";
import * as filterActions from '../filterbox/actions';


import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { Visibility, Launch, Create } from "@material-ui/icons";

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        pageNumber: 0,
        pageSize: 10,
      },
      filterBox: {
        holderId: "",
        insuredId: "",
        projectId: "",
        documentStatus: "",
        documentType: "",
        garbage: "",
        urgent: "",
        archived: "",
        documentId: "",
      },
      sortModel: [
        {
          field: "fileName",
          sort: "asc",
        },
      ],
      showFilterBox: true,
      currentDocument: "",
      showProcessingModal: false,
      showExportModal: false,
      queueId: 0,
      queueOptions: null,
    };
  }

  componentDidMount() {
    const { documentQueueDefinitionsActions, loginActions } = this.props;
    documentQueueDefinitionsActions.fetchDocumentQueueDefinitions({ withoutPag: true, documentsPage: true });
    loginActions.getProfile();
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.queueOptions === null &&
      this.props.login.profile.hasOwnProperty("CFRole") &&
      this.props.documentQueueDefinitions &&
      this.props.documentQueueDefinitions.list &&
      this.props.documentQueueDefinitions.list.length > 0
    ) {
      const { actions, commonActions, login, documentQueueDefinitions } = this.props;
      let query = Utils.getFetchQuery("UrgentFlag", 1, "DESC");
      const role = login.profile.CFRole.Name;
      const queueOptions = Utils.getOptionsList(
        role === CF_THIRD_PARTY_DATA_ENTRY_NAME ? null : " Non configured queue ",
        documentQueueDefinitions.list,
        "Name",
        "QueueId",
        "IsDefault"
      );
      if (role !== CF_THIRD_PARTY_DATA_ENTRY_NAME) {
        query.showAll = true;
        queueOptions.unshift({
          label: "-- Show All --",
          value: "showAll",
        });
      }
      let filterBox = { ...this.state.filterBox };
      filterBox.garbage = 0;
      filterBox.archived = 0;
      query.garbage = 0;
      query.archived = 0;
      this.setState({ filterBox });
      this.setState({ queueOptions });
      query = Utils.addSearchFiltersToQuery(query);
      if (role === CF_THIRD_PARTY_DATA_ENTRY_NAME) {
        query.orderDirection = "ASC";
        let sortModel = [{ field: "dateCreated", sort: "asc" }];
        filterBox.queueId = 16;
        query.orderBy = "dateCreated";
        query = Utils.addSearchFiltersToQuery(query, filterBox);
        this.setState({
          sortModel,
          queueId: 16,
        });
      } else {
        let sortModel = [{ field: "UrgentFlag", sort: "desc" }];
        this.setState({
          sortModel,
        });
      }
      // actions.fetchDocuments(query);
      commonActions.setBreadcrumbItems([]);
    }
  }

  handleSortModelChange = (sortModel) => {
    if (JSON.stringify(sortModel) != JSON.stringify(this.state.sortModel)) {
      // get base query
      const { pageNumber, pageSize } = this.state.filter;
      const orderDirection = sortModel[0].sort === "asc" ? "ASC" : "DESC";
      let query = Utils.getFetchQuery(sortModel[0].field, pageNumber + 1, orderDirection, pageSize);
      // add search filters
      query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);
      // fetch using query
      if (this.state.queueId == 0) {
        query.showAll = true;
        delete query.queueId;
      }
      this.props.actions.fetchDocuments(query);
      // save new active tab and order
      let newState = {
        sortModel,
      };
      this.setState(newState);
    }
  };

  setPageFilter = (pageNumber, force, pageSize) => {
    if (force || this.state.filter.pageNumber !== pageNumber) {
      // get base query
      const { field, sort } = this.state.sortModel[0];
      const orderDirection = sort === "asc" ? "ASC" : "DESC";
      let query = Utils.getFetchQuery(field, pageNumber + 1, orderDirection, pageSize);
      // add search filters
      query = Utils.addSearchFiltersToQuery(query, this.state.filterBox);
      // fetch using query
      if (this.state.queueId === 0) {
        query.showAll = true;
        delete query.queueId;
      }
      this.props.actions.fetchDocuments(query);
      // save pagenumber
      this.setState({
        filter: {
          pageNumber,
          pageSize,
        },
      });
    }
  };

  handlePageChange = (page) => {
    const { pageSize } = this.state.filter;
    this.setPageFilter(page, false, pageSize);
  };

  handlePageSizeChange = (pageSize) => {
    const { pageNumber } = this.state.filter;
    this.setPageFilter(pageNumber, true, pageSize);
  };

  onNextDataEntry = () => {
    const { actions } = this.props;
    const cfRoleId = this.props.login.profile.CFRoleId;
    this.props.commonActions.setLoading(true);
    this.props.actions.fetchNextDocument(
      {
        queueId: this.state.queueId,
      },
      (CertificateId) => {
        this.props.commonActions.setLoading(false);
        let query = "";
        if (cfRoleId === CF_THIRD_PARTY_DATA_ENTRY_ID) {
          query = Utils.getFetchQuery("dateCreated", 1, "ASC");
        } else {
          query = Utils.getFetchQuery("UrgentFlag", 1, "DESC");
        }
        query.garbage = 0;
        query.archived = 0;
        query = Utils.addSearchFiltersToQuery(query, { queueId: this.state.queueId });
        actions.fetchDocuments(query);

        if (CertificateId) {
          window.open(`/certfocus/processing/${CertificateId}`, "_blank");
        } else {
          Swal({
            type: "warning",
            title: "Warning",
            text: "No available document found",
          });
        }
      }
    );
  };

  submitFilterForm = async(values) => {
    // get base query
    const { field, sort } = this.state.sortModel[0];
    const pageNumber = 0;
    const orderDirection = sort === "asc" ? "ASC" : "DESC";
    let query = Utils.getFetchQuery(field, pageNumber + 1, orderDirection);
    let garbageValue = values.garbage === "null" ? "" : values.garbage;
    let archivedValue = values.archived === "null" ? "" : values.archived;
    let urgentValue = values.urgent === "null" ? "" : values.urgent;

    // add search filters
    const filterBox = {
      documentId: values.documentId || "",
      holderId: values.holderId ? values.holderId.value : "",
      insuredId: values.insuredId ? values.insuredId.value : "",
      projectId: values.projectId ? values.projectId.value : "",
      documentStatusId: values.documentStatus || "",
      documentTypeId: values.documentType || "",
      garbage: garbageValue,
      urgent: urgentValue,
      archived: archivedValue,
    };
    let currentQueueId = this.state.queueId;
    if (!(typeof currentQueueId === '') && currentQueueId !== 0) {
      filterBox.queueId = currentQueueId;
    } else if (Number(currentQueueId) === 0) {
      filterBox.showAll = true;
    }
    let saveFilter = { tab: 'documents' }
    saveFilter.module = 'GlobalDocuments';
    saveFilter.objectId = null;
    saveFilter.filterBox = {
      documentId: values.documentId || "",
      holderId: values.holderId ? values.holderId : "",
      insuredId: values.insuredId ? values.insuredId : "",
      projectId: values.projectId ? values.projectId : "",
      documentStatusId: values.documentStatus || "",
      documentTypeId: values.documentType || "",
      garbage: values.garbage || "",
      urgent: values.urgent || "",
      queueId: this.state.queueId,
      archived: values.archived || "",
    };
    this.setState({ queueId: this.state.queueId, });
    await this.props.filterActions.saveDefaultFilters('FilterDocuments', saveFilter);
    //console.log('filterBox', filterBox);
    query = Utils.addSearchFiltersToQuery(query, filterBox);
    // fetch using query
    await this.props.actions.fetchDocuments(query);
    // save searchterm and pagenumber
    this.setState({
      filterBox,
      filter: {
        pageNumber,
        pageSize: 10,
      },
    });
  };

  openModal = () => {
    this.setState({ currentProjectInsured: null });
    this.props.actions.setShowModal(true);
  };

  closeModal = () => {
    this.props.actions.setShowModal(false);
  };

  closeModalAndRefresh = () => {
    this.props.actions.setShowModal(false);
    this.setPageFilter(null, 1, true);
  };

  editDocument = (document) => {
    this.setState({ currentDocument: document });
    this.props.actions.setShowModal(true);
  };

  renderOptions = (opt, idx) => {
    return (
      <option value={opt.value} key={idx}>
        {opt.label}
      </option>
    );
  };

  onChangeQueue = (e) => {
    const { value } = e.target;
    let query = Utils.getFetchQuery("UrgentFlag", 1, "DESC");
    const filterBox = this.state.filterBox;
    if (
      this.props.login.profile &&
      this.props.login.profile.CFRole &&
      this.props.login.profile.CFRole.Name === "Third Party Data Entry"
    ) {
      filterBox.garbage = "0";
      filterBox.archived = "0";
      filterBox.documentStatus = "10";
    }
    if (value === "showAll") {
      query.showAll = true;
      this.setState({ queueId: 0 }, () => {
        delete filterBox.queueId;
        query = Utils.addSearchFiltersToQuery(query, filterBox);
        this.props.actions.fetchDocuments(query);
      });
    } else {
      this.setState({ queueId: value }, () => {
        filterBox.queueId = this.state.queueId;
        query = Utils.addSearchFiltersToQuery(query, filterBox);
        this.props.actions.fetchDocuments(query);
      });
    }
    this.setState({
      filter: {
        pageNumber: 0,
        pageSize: 10,
      },
      sortModel: [
        {
          field: "UrgentFlag",
          sort: "DESC",
        },
      ],
    });
  };

  openProcessingModal = (e, documentData, canViewDataEntry) => {
    //console.log('currentDocument: ', documentData);
    documentData.canViewDataEntry = canViewDataEntry;
    this.setState({ showProcessingModal: true, currentDocument: documentData });
  };

  closeProcessingModal = () => {
    this.setState({ showProcessingModal: false, currentDocument: null });
  };

  openExportModal = () => {
    this.setState({ showExportModal: true });
  };

  closeExportModal = () => {
    this.setPageFilter({}, 1, true, 10);
    this.setState({ showExportModal: false });
  };

  renderGarbageDocumentLink(document, canGarbageDocument) {
    const { garbagedDocument, nonGarbagedDocument } = this.props.local.strings.documents.documentsList;
    const garbageFlag = document.GarbageFlag;
    if (!canGarbageDocument) {
      return !garbageFlag ? (
        <React.Fragment>{nonGarbagedDocument}</React.Fragment>
      ) : (
        <React.Fragment>{garbagedDocument}</React.Fragment>
      );
    } else {
      return !garbageFlag ? (
        <a
          onClick={(e) => this.garbageDocument(e, document, 1)}
          className="cell-table-link text-uppercase"
          href="javascript:void(0)"
        >
          {nonGarbagedDocument}
        </a>
      ) : (
        <a
          onClick={(e) => this.garbageDocument(e, document, 0)}
          className="cell-table-link text-uppercase"
          href="javascript:void(0)"
          style={{ color: "#F00" }}
        >
          {garbagedDocument}
        </a>
      );
    }
  }
  resetStateToDefault = async () => {
    let filterBox = {
      holderId: "",
      insuredId: "",
      projectId: "",
      documentStatus: "",
      documentType: "",
      garbage: "",
      urgent: "",
      archived: "",
      documentId: "",
    }
    await this.setState({ filterBox })
  }

  garbageDocument = (e, document, newGarbageStatus) => {
    const garbageTitle = newGarbageStatus === 1 ? "Garbage" : "Ungarbage";
    const garbageText = newGarbageStatus === 1 ? "garbage" : "ungarbage";

    Swal({
      title: `${garbageTitle} Project`,
      text: `Are you sure you want to ${garbageText} document # ${document.DocumentID}?`,
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#2E5965",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    }).then((result) => {
      if (result.value) {
        const payload = {
          documentId: document.DocumentID,
          certificateId: document.CertificateID,
          projectInsuredId: document.ProjectInsuredID,
          holderId: document.HolderID,
          projectId: document.ProjectID,
          insuredId: document.InsuredID,
          garbage: newGarbageStatus,
        };
        this.props.actions.garbageDocument(payload, (success) => {
          this.props.commonActions.setLoading(false);
          this.setPageFilter(1, true);
        });
      }
    });
  };

  render() {
    const { filterBtn, headers, viewDocument, processDocument, viewDataEntry } =
      this.props.local.strings.documents.documentsList;

    const {
      fileNameColumn,
      dateCreatedColumn,
      hiringClientColumn,
      projectColumn,
      insuredColumn,
      documentTypeColumn,
      documentStatusColumn,
      urgentColumn,
      garbageColumn,
      archivedColumn,
      topLayerColumn,
      documentIdColumn,
      transcribedByColumn
    } = headers;

    const canViewFilterBox = this.props.login.rolesAccessPermissions.find(
      (x) => x.MasterTab === "documents" && x.SectionTab === "view_filter_box"
    );
    const canDoDataEntry = this.props.login.rolesAccessPermissions.find(
      (x) => x.MasterTab === "documents" && x.SectionTab === "documents_operation_data_entry"
    );
    const canProcessDocument = this.props.login.rolesAccessPermissions.find(
      (x) => x.MasterTab === "documents" && x.SectionTab === "documents_operation_process_document"
    );
    const canGarbageDocument = this.props.login.rolesAccessPermissions.find(
      (x) => x.MasterTab === "documents" && x.SectionTab === "documents_operation_garbage_document"
    );
    let documents = this.props.documents.list;

    let { totalAmountOfDocuments, documentsPerPage, fetchingDocuments } = this.props.documents;

    const columns = [
      {
        field: "documentId",
        headerName: documentIdColumn,
        flex: 1,
        minWidth: 200,
      },
      {
        field: "transcribedBy",
        headerName: transcribedByColumn,
        flex: 1,
        minWidth: 300,
        valueFormatter: (params) => {
          if (!params.row || !params.row.process || !params.row.process.document) return ''
          const { CertificateID, TranscribedBy } = params.row.process.document;
          return CertificateID != null ? (TranscribedBy || NOT_CAPTURED) : ''
        }
      },
      {
        field: "fileName",
        headerName: fileNameColumn,
        flex: 1,
        minWidth: 400,
      },
      {
        field: "dateCreated",
        headerName: dateCreatedColumn,
        flex: 1,
        minWidth: 200,
      },
      {
        field: "holderName",
        headerName: hiringClientColumn,
        flex: 1,
        minWidth: 400,
      },
      {
        field: "projectName",
        headerName: projectColumn,
        flex: 1,
        minWidth: 400,
      },
      {
        field: "insuredName",
        headerName: insuredColumn,
        flex: 1,
        minWidth: 400,
      },
      {
        field: "documentTypeId",
        headerName: documentTypeColumn,
        flex: 1,
        minWidth: 300,
      },
      {
        field: "documentStatus",
        headerName: documentStatusColumn,
        flex: 1,
        minWidth: 300,
      },
      {
        field: "topLayer",
        headerName: topLayerColumn,
        flex: 1,
        minWidth: 150,
      },
      {
        field: "UrgentFlag",
        headerName: urgentColumn,
        flex: 1,
        minWidth: 150,
      },
      {
        field: "garbageFlag",
        headerName: garbageColumn,
        renderCell: (params) => {
          const { Exempt, document, canGarbageDocument } = params.value;
          return !Exempt && this.renderGarbageDocumentLink(document, canGarbageDocument);
        },
        valueFormatter: (params) => {
          const { garbagedDocument, nonGarbagedDocument } = this.props.local.strings.documents.documentsList;
          const { GarbageFlag } = params.value.document;
          return GarbageFlag ? garbagedDocument : nonGarbagedDocument;
        },
        flex: 1,
        minWidth: 200,
      },
      {
        field: "archived",
        headerName: archivedColumn,
        flex: 1,
        minWidth: 150,
      },
      {
        field: "view",
        renderHeader: () => <div />,
        disableColumnMenu: true,
        disableExport: true,
        sortable: false,
        renderCell: (params) => {
          const { DocumentUrl } = params.value;
          return (
            <a href={DocumentUrl} className="cell-table-link" style={{ whiteSpace: "nowrap" }}>
              <Visibility style={{ fontSize: 15 }} /> {viewDocument}
            </a>
          );
        },
      },
      {
        field: "viewDataEntry",
        renderHeader: () => <div />,
        disableColumnMenu: true,
        disableExport: true,
        sortable: false,
        renderCell: (params) => {
          const { CertificateID, canViewDataEntry, canDoDataEntry, Archived, GarbageFlag, Exempt } = params.value;
          return (
            CertificateID &&
            canViewDataEntry === true &&
            canDoDataEntry &&
            !Archived &&
            !GarbageFlag &&
            !Exempt && (
              <a
                href={`/certfocus/processing/${CertificateID}`}
                target={`_blank`}
                className="cell-table-link"
                style={{ whiteSpace: "nowrap" }}
              >
                <Launch fontSize="small" style={{ fontSize: 15 }} /> {viewDataEntry}
              </a>
            )
          );
        },
      },
      {
        field: "process",
        renderHeader: () => <div />,
        disableColumnMenu: true,
        disableExport: true,
        sortable: false,
        renderCell: (params) => {
          const { Archived, GarbageFlag, canProcessDocument, document, canViewDataEntry } = params.value;
          return (
            !Archived &&
            !GarbageFlag &&
            canProcessDocument && (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                onClick={(e) => this.openProcessingModal(e, document, canViewDataEntry)}
                className="cell-table-link"
                href="javascript:void(0)"
                style={{ whiteSpace: "nowrap" }}
              >
                <Create style={{ fontSize: 15 }} /> {processDocument}
              </a>
            )
          );
        },
      },
    ];
    const rowsPerPageOptions = [10, 25, 50, 100];

    const rows = documents.map((document) => {
      const {
        DocumentID,
        FileName,
        DateCreated,
        HolderName,
        ProjectName,
        ProjectName2,
        InsuredName,
        DocumentStatus,
        UrgentFlag,
        GarbageFlag,
        CertificateID,
        DocumentUrl,
        canViewDataEntry,
        documentTypeName,
        Archived,
        Exempt,
        TopLayer,
        TranscribedBy
      } = document;

      const projectName = ProjectName2 ? ProjectName.concat(" - ", ProjectName2) : ProjectName;

      return {
        id: uuid(),
        documentId: DocumentID,
        transcribedBy: TranscribedBy,
        fileName: FileName,
        dateCreated: DateCreated && moment(DateCreated).utc().format("MM/DD/YYYY hh:mm:ss A"),
        holderName: HolderName,
        projectName: projectName,
        insuredName: InsuredName,
        documentTypeId: documentTypeName,
        documentStatus: DocumentStatus,
        topLayer: TopLayer ? "yes" : "no",
        UrgentFlag: UrgentFlag ? "yes" : "no",
        garbageFlag: { Exempt, document, canGarbageDocument },
        archived: Archived ? "yes" : "no",
        view: { DocumentUrl },
        viewDataEntry: { CertificateID, canViewDataEntry, canDoDataEntry, Archived, GarbageFlag, Exempt },
        process: { Archived, GarbageFlag, canProcessDocument, document, canViewDataEntry },
      };
    });

    const { pageNumber, pageSize } = this.state.filter;

    return (
      <div className="container-fluid">
        {this.props.login && this.props.login.profile.length > 0 ? (
          <RolAccess
            masterTab={"documents"}
            sectionTab={"view_document"}
            component={() => null}
            default={() => <Redirect push to="/profile" />}
          />
        ) : null}
        <Modal
          show={this.state.showProcessingModal}
          onHide={this.closeProcessingModal}
          className="add-item-modal add-hc"
        >
          <Modal.Body className="add-item-modal-body mt-0">
            <ProcessingModal
              onHide={this.closeProcessingModal}
              close={this.closeProcessingModal}
              document={this.state.currentDocument}
              onSubmit={this.onProcessing}
              currentUploadData={{
                holderId:
                  this.state.currentDocument && this.state.currentDocument.HolderID
                    ? this.state.currentDocument.HolderID
                    : "",
              }}
            />
          </Modal.Body>
        </Modal>

        <section className="list-view-header">
          <div className="row" style={{ maxHeight: "60px" }}>
            <div className="col-sm-12">
              <nav className="list-view-nav">
                <ul>
                  <RolAccess
                    masterTab={"documents"}
                    sectionTab={"view_filter_box"}
                    component={() => (
                      <React.Fragment>
                        <li>
                          <div className="form-group documents-queue">
                            <label htmlFor="queue">Choose a Queue: </label>
                            <div className="select-wrapper">
                              <select 
                                value={this.state.queueId}
                                name="queue" 
                                onChange={this.onChangeQueue} 
                                style={{ width: "230px" }}>
                                {this.state.queueOptions !== null
                                  ? this.state.queueOptions.map(this.renderOptions)
                                  : null}
                              </select>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a
                            className="list-view-nav-link nav-bn icon-login-door no-overlapping"
                            onClick={() => {
                              this.setState({ showFilterBox: !this.state.showFilterBox });
                            }}
                          >
                            {filterBtn}
                          </a>
                        </li>
                      </React.Fragment>
                    )}
                    default={() => (
                      <li>
                        <div className="form-group documents-queue">
                          <label htmlFor="queue">Choose a Queue: </label>
                          <div className="select-wrapper">
                            <select
                              name="queue"
                              onChange={this.onChangeQueue}
                              value={this.state.queueId}
                              style={{ width: "230px" }}
                            >
                              {this.state.queueOptions !== null
                                ? this.state.queueOptions.map(this.renderOptions)
                                : null}
                            </select>
                          </div>
                        </div>
                      </li>
                    )}
                  />
                  {this.state.queueId && !canViewFilterBox ? (
                    <li>
                      <a
                        onClick={this.onNextDataEntry}
                        className="list-view-nav-link nav-bn no-overlapping"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {viewDataEntry}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </nav>
            </div>
          </div>
        </section>

        {this.state.showFilterBox && (
          <RolAccess
            masterTab={"documents"}
            sectionTab={"view_filter_box"}
            component={() => (
              <section className="list-view-filters">
                <FilterDocuments onSubmit={this.submitFilterForm}
                  handleExportModalShow={this.openExportModal}
                  queueId={this.state.queueId}
                  setQueueId={(value) => {
                    this.setState({ queueId: Number(value) })
                  }}
                  setInitialFilterBox={(values) => this.setState({ filterBox: values })}
                  resetStateToDefault={this.resetStateToDefault}
                  />
              </section>
            )}
          />
        )}

        <DataGrid
          loading={fetchingDocuments}
          columns={columns}
          rows={rows}
          paginationMode="server"
          pageSize={pageSize}
          onPageSizeChange={this.handlePageSizeChange}
          rowCount={totalAmountOfDocuments}
          rowsPerPageOptions={rowsPerPageOptions}
          page={pageNumber}
          onPageChange={this.handlePageChange}
          sortingOrder={["asc", "desc"]}
          onSortModelChange={this.handleSortModelChange}
          components={{
            Toolbar: () => (
              <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport
                  csvOptions={{
                    fileName: `Vertikal_Documents_${moment().utc().format("YYYY-MM-DD_hh-mm-A")}`,
                  }}
                />
              </GridToolbarContainer>
            ),
          }}
          autoHeight
          disableColumnFilter
          disableSelectionOnClick
          componentsProps={{
            GridToolbarExport: {
              csvOptions: {
                fileName: `Vertikal_Documents_${moment().utc().format("YYYY-MM-DD_hh-mm-A")}`,
              },
            },
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { filterbox } = state.filterBox;
  let initialValueState = {};
  if (
    filterbox &&
    filterbox.FilterDocuments &&
    typeof filterbox.FilterDocuments === "object"
  ) {
    initialValueState = {
      ...filterbox.FilterDocuments,
      documentType: filterbox.FilterDocuments.documentTypeId,
      documentStatus: filterbox.FilterDocuments.documentStatusId
    };
  }
  return {
    documents: state.documents,
    local: state.localization,
    login: state.login,
    documentQueueDefinitions: state.documentQueueDefinitions,
    initialValues: initialValueState
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    documentQueueDefinitionsActions: bindActionCreators(documentQueueDefinitionsActions, dispatch),
    filterActions: bindActionCreators(filterActions, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Documents));
