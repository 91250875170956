import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getWorkFlow, getWorkFlowComponents, saveWorkFlowComponents, resetWorkFlowComponents } from './service';
import * as departmentsActions from '../../../../certfocus/settings/departments/actions';
import _ from 'lodash';
import Swal from 'sweetalert2';
import Utils from '../../../../../lib/utils';
import PTable from '../../../../common/ptable';
import RolAccess from './../../../../common/rolAccess';
import * as commonActions from '../../../../common/actions';
import * as workflowActions from './actions';
import {
  ADD_WAITING_TASK_WF_COMPONENT_ID, BACKSPACE_ASCII, CF_ADD_NON_WAITING_TASK, CF_ADD_WAITING_TASK, CF_DAYS_UNTIL_VALIDATION, CF_DAYS_UNTIL_VALIDATION_DAYS_PARAMS, CF_EMAIL_OPTION, CF_FROM_EMAIL_ADDRESS, CF_MAIL_TEMPLATE, CF_POLICY_VALIDATION_PROCESS, CF_SEND_MAIL, CF_TO_ADDRESS
} from '../../../../../lib/appConstants';
import Select from 'react-select';
import {AsyncPaginate} from 'react-select-async-paginate';
import loadOptions from './loadOptions';
import './workflow.css';
const Alerts = require('../../../../alerts');
class Workflow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableOrderActive: 'description',
      filter: {
        pageNumber: 1
      },
      order: {
        detail: 'desc',
        description: 'asc'
      },
      loading: false,
      isTableVisible: true,
      currentWorkflow: {},
      workflows: [],
      components: [],
      stepsToRemoveAssocTasks: [],
      showRoles: [],
      showUsers: [],
      showDepartments:[],
      caseTagsStyles: {
        multiValue: (base, state) => {
            return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
        },
        multiValueLabel: (base, state) => {
            return state.data.isFixed
                ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
                : base;
        },
        multiValueRemove: (base, state) => {
            return state.data.isFixed ? { ...base, display: 'none' } : base;
        },
      },
      roleTags: [],
      userTags: [],
      departmentTags: [],
      emailOptionTags: {},
      users: [],
      roles: [],
      departmentList:[],
      possible_values:[],
      validationParameters: {
        // componentParameters to be validated
        dropdownComponentParameterIds : [CF_EMAIL_OPTION, CF_MAIL_TEMPLATE, CF_DAYS_UNTIL_VALIDATION_DAYS_PARAMS]},
      errors: {},
      activeWfCount: 0
    }
     this.onViewWorkflow = this.onViewWorkflow.bind(this);
     this.onAddComponent = this.onAddComponent.bind(this);
     this.onSaveChanges = this.onSaveChanges.bind(this);
     this.onResetWorkflow = this.onResetWorkflow.bind(this);
     this.clickOnColumnHeader = this.clickOnColumnHeader.bind(this);
     this.setPageFilter = this.setPageFilter.bind(this);
     this.renderParameter = this.renderParameter.bind(this);
     this.renderComponent = this.renderComponent.bind(this);
     this.buildComponents = this.buildComponents.bind(this);
     this.buildComponentParams = this.buildComponentParams.bind(this);
     this.buildPayload = this.buildPayload.bind(this);
     this.handleTagsInput = this.handleTagsInput.bind(this);
     this.populateRoleAndUsersValues = this.populateRoleAndUsersValues.bind(this);
  }

  componentWillMount() {
    
    getWorkFlow(this.props.holderId, this.props.userProfile.authToken, (err, data) => {
      this.setState({ workflows: data })
    });
    this.props.departmentsActions.getDepartments({
      orderBy: 'name',
      orderDirection: 'ASC',
      archived: 0,
      withoutPag: true
    });
    const { ssRoleOptions, cfRoleOptions } = this.props.register;

    const isSelfService = ssRoleOptions.find(role => role.value === parseInt(this.props.roleId));

    const roles = isSelfService ? ssRoleOptions : cfRoleOptions.slice(1);
    const roleOptionsList = Utils.getOptionsList('', roles, 'label', 'value', 'label', 0);
    this.setState({ roles: roleOptionsList.filter(i => i.value) });
  }
  

  componentDidUpdate() {
    const tempArr = [],tempDepartmentList=[];
    if (this.props.users.list.length && this.state.users.length === 0) {
      this.props.users.list.forEach(x => {
        tempArr.push({ value: x.id, label: x.id, isFixed: false })
      });

      this.setState({ users: tempArr });
    }
    if(this.props.departments.list.length&&this.state.departmentList.length === 0){
      this.props.departments.list.forEach(x => {
        tempDepartmentList.push({ value: x.id, label: x.name })
      });
      this.setState({ departmentList:tempDepartmentList});

    }
  
  }

  clickOnColumnHeader = (e, field) => {
    if (field === 'view') return;
    let newState = {
      tableOrderActive: field,
      order: {
        detail: field === 'detail' ? 'asc' : 'desc',
        description: field === 'description' ? 'asc' : 'desc',
      }
    };
    newState.order[field] = this.state.order[field] === 'asc' ? 'desc' : 'asc';
    this.setState(newState);
  }

  setPageFilter = (e, pageNumber) => {
    if (this.state.filter.pageNumber !== pageNumber) {
      // save page number
      this.setState({
        filter: {
          pageNumber
        }
      });

    }
  }

  async populateRoleAndUsersValues(components) {
    let tempRoleTags = [], tempUserTags = [], tempDepartmentsTags = [], tempShowUsers = [], tempShowRoles = [], tempShowDepartmentsTags = [];
    const componentsPromises = components.map((_component, _index) => {
      let comName = _component.current_value.name;
      let showRoleAndIds = false;
      if (comName === "Send Email" || comName === "Add Non-Waiting Task" || comName === "Add Waiting Task") {
        showRoleAndIds = true;
      }
      if (showRoleAndIds) {
        const assignToType = _component.current_parameters.filter(x => x.name.toLowerCase() === 'Assign To Type'.toLowerCase());
        if (assignToType && assignToType.length && assignToType[0].value === '1') {
          const assignToRoleValues = _component.current_parameters.filter(x => x.name.toLowerCase() === 'Assign To Role'.toLowerCase());

          if (assignToRoleValues && assignToRoleValues[0].value && assignToRoleValues[0].value !== '') {
            const rolesArray = assignToRoleValues[0].value.split(',');
            const rolesPayloadArray = [];
            if (rolesArray && rolesArray.length > 0) {
              rolesArray.forEach(roleVal => {
                let current_tag = this.state.roles.filter(_role => _role.value == roleVal);
                if (current_tag && current_tag.length > 0) {
                  rolesPayloadArray.push({
                    value: roleVal,
                    label: current_tag[0].label
                  });
                }
              });
            }
            tempShowRoles.push(_index);
            tempRoleTags.push([...rolesPayloadArray]);
            tempUserTags.push([]);
            tempDepartmentsTags.push([]);
          } else {
            tempRoleTags.push([]);
            tempUserTags.push([]);
            tempDepartmentsTags.push([]);
          }
        }
        if (assignToType && assignToType.length && assignToType[0].value === '2') {
          const assignToUserValues = _component.current_parameters.filter(x => x.name.toLowerCase() === 'Assign To ID'.toLowerCase());
          if (assignToUserValues && assignToUserValues[0].value && assignToUserValues[0].value !== "") {
            const usersArray = assignToUserValues[0].value.split(',');
            const usersPayloadArray = [];
            if (usersArray && usersArray.length > 0) {
              usersArray.forEach(userVal => {
                usersPayloadArray.push({
                  value: userVal,
                  label: userVal
                })
              });
            }
            tempShowUsers.push(_index);
            tempUserTags.push([...usersPayloadArray]);
            tempRoleTags.push([]);
            tempDepartmentsTags.push([]);
          } else {
            tempUserTags.push([]);
            tempRoleTags.push([]);
            tempDepartmentsTags.push([]);
          }
        }

        if (assignToType && assignToType.length && assignToType[0].value === CF_FROM_EMAIL_ADDRESS.toString()) {
          const assignToDepartValues = _component.current_parameters.filter(x => x.name.toLowerCase() === 'Assign To Departments'.toLowerCase());
          if (assignToDepartValues &&  assignToDepartValues[0].value&& assignToDepartValues[0].value!=="") {
            const departmentsArray = assignToDepartValues[0].value.split(',');
            const departmentsPayloadArray = [];
            if (departmentsArray && departmentsArray.length > 0) {
              departmentsArray.forEach(departVal => {
                let current_tag = this.state.departmentList.filter(depart => depart.value == departVal);
                departmentsPayloadArray.push({
                  value: departVal,
                  label: current_tag[0].label
                });
              });
            }
            tempShowDepartmentsTags.push(_index);
            tempUserTags.push([]);
            tempRoleTags.push([]);
            tempDepartmentsTags.push([...departmentsPayloadArray]);
          } else {
            tempUserTags.push([]);
            tempRoleTags.push([]);
            tempDepartmentsTags.push([]);
          }
        }
      } else {
        tempRoleTags.push([]);
        tempUserTags.push([]);
        tempDepartmentsTags.push([]);
      }
    });
    this.setState({ userTags: tempUserTags, roleTags: tempRoleTags, showUsers: tempShowUsers, showRoles: tempShowRoles, departmentTags: tempDepartmentsTags, showDepartments: tempShowDepartmentsTags });
    const componentsPromisesResolved = await Promise.all(componentsPromises);
    return componentsPromisesResolved;
  }

  handleTagsInput(type, tags, idx, component, parameter) {
    var tempTags = [...this.state[type]];
    tempTags[idx] = tags;
    this.setState({ [type]: tempTags });
    if (parameter && parameter.id) {
      const index = _.findIndex(component.current_parameters,
      function (o) { return o.componentParameterId.toString() === parameter.id.toString() });
      const componentParams = component.current_parameters;
      if(tags.value) {
        componentParams[index].value = tags.value;
      } else {
        componentParams[index].value = tags.map(tag => tag.value);
      }

      const newComponents = this.state.components.map(
        (item, index) => item.positionIndex === component.positionIndex ?
          {
            ...item,
            current_parameters: componentParams
          } : item
      );
      this.setState({ components: newComponents });
    }
  }
 
  onViewWorkflow = (workflow) => {
    if (this.state.loading || !workflow.workflowId) return;

    this.setState({
      currentWorkflow: workflow
    });

    this.setState({ loading: true });
    getWorkFlowComponents(workflow.workflowId, this.props.userProfile.authToken, (err, data) => {
      if (!data || !data.possible_values) return;
    
      const components = this.buildComponents(data.components, data.possible_values);
      this.populateRoleAndUsersValues(components);
      this.setState({
        loading: false,
        isTableVisible: false,
        currentWorkflow: workflow,
        components,
        possible_values: data.possible_values,
        activeWfCount: data.activeWfCount
      });
    });
    
  }

  validateData = (payload) =>{
    const errors = {}
    const components = payload.components
    let previousDaysValue = 0;
    components.forEach((component)=>{
      if(!component.componentId) errors[component.positionIndex -1 ] = {invalidComponent : true }
        // Policy validation -> Days until validation -> Days field validation
        if (component && component.componentId == CF_DAYS_UNTIL_VALIDATION) {
          component.parameters.forEach((componentParameter) => {
            const fieldStateValue = errors[component.positionIndex -1 ] && errors[component.positionIndex -1 ][component.componentId] ? {...errors[component.positionIndex -1 ][component.componentId]} : {}
            if(this.state.validationParameters.dropdownComponentParameterIds.includes(componentParameter.componentParameterId)) {
              const daysValue = parseInt(`${componentParameter.value}`, 10);
              if(daysValue < 30 || daysValue > 275){
                errors[component.positionIndex -1 ] =  {...errors[component.positionIndex] ,[component.componentId] : { ...fieldStateValue , [componentParameter.componentParameterId] : "Days should be between 30 to 275"}}
              }else if(component.positionIndex != 1){
                if(daysValue <= previousDaysValue){
                  errors[component.positionIndex -1 ] =  {...errors[component.positionIndex] ,[component.componentId] : { ...fieldStateValue , [componentParameter.componentParameterId] : "Days must be greater than the previous entry."}}
                }
              }
              previousDaysValue = daysValue;
            }
          })
        }

        component.parameters.forEach((componentParameter)=>{
          const fieldStateValue = errors[component.positionIndex -1 ] && errors[component.positionIndex -1 ][component.componentId] ? {...errors[component.positionIndex -1 ][component.componentId]} : {}
          if(this.state.validationParameters.dropdownComponentParameterIds.includes(componentParameter.componentParameterId) ){
            if(componentParameter.value === ""){
              errors[component.positionIndex -1 ] =  { ...errors[component.positionIndex] ,[component.componentId] : { ...fieldStateValue , [componentParameter.componentParameterId] : "Field required"}  }
            }
          }

          // Validate 'To Address' only when EmailOption value is 3
          if(
            // check if CF-sendMail component is selectes
            component.componentId === CF_SEND_MAIL  &&
            // check if CF-email option parameter is selected
            component.parameters[0].value === CF_FROM_EMAIL_ADDRESS.toString() && 
            // check if current parameter is CF_TO_ADDRESS
            componentParameter.componentParameterId === CF_TO_ADDRESS &&
            // check if CF_TO_ADDRESS value is null
            !Utils.isEmailValid(componentParameter.value) ){
            errors[component.positionIndex -1 ] =  { ...errors[component.positionIndex] ,[component.componentId] : { ...fieldStateValue , [componentParameter.componentParameterId] : "Invalid Email"}  }
          }

        })
    })
    this.setState({...this.state , errors})
    return errors
  }

  onSaveChanges = () => {
    const payload = this.buildPayload();
    const errors = this.validateData(payload, true);
    if (!_.isEmpty(errors)) {
      return;
    }
    
    Swal({
      title: ``,
      text: `Proceeding will reset the workflow of ${this.state.activeWfCount} records to step 1. Are you sure you want to continue?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Accept'
    }).then((result) => {
      if (result.value) {

        this.setState({ loading: true, isTableVisible: true });

        // To reset all active WF to step 1
        payload.resetToFirstStep = true;

        saveWorkFlowComponents(payload, this.props.userProfile.authToken, (err) => {
          if (!err) {
            // clear assoc tasks from removed components
            if (this.state.stepsToRemoveAssocTasks.length > 0) {
              this.props.workflowActions.removeWorkflowAssocTasks(this.state.stepsToRemoveAssocTasks, (err) => {
                if (err) console.error('Cannot remove associated tasks for wf components. Please contact the administrator.');
              });
            }
          }
          this.setState({ loading: false, isTableVisible: true });
        });
      } else {
        this.setState({isTableVisible: true})
      }
    });
  }

  onResetWorkflow = () => {

    const onResetConfirmation = (confirmed) => {
      if (!confirmed) return;
      this.setState({ loading: true, isTableVisible: true });
      resetWorkFlowComponents(this.state.currentWorkflow.workflowId, this.props.userProfile.authToken, (err, data) => {
        if (!data || !data.possible_values) return;
        const components = this.buildComponents(data.components, data.possible_values);
        this.setState({
          loading: false,
          isTableVisible: false,
          components,
          possible_values: data.possible_values
        });
      });
    }

    const alertContent = {
      title: 'Reset To Default',
      text: 'Are you sure you want to reset this workflow?',
      btn_no: 'Cancel',
      btn_yes: 'Accept'
    }
    Alerts.showActionConfirmation(alertContent, onResetConfirmation);
  }

  onAddComponent = () => {
    const newComponents = this.state.components;
    newComponents.push({
      positionIndex: this.state.components.length + 1,
      current_value: { componentId: "" },
      current_parameters: []
    });
    this.setState({ components: newComponents });
  }

  onAddComponentOnSpecificIndex = (index) => {
    const newComponents = this.state.components;
    const newComponent = {
      positionIndex: index+1,
      current_value: { componentId: "" },
      current_parameters: []
    };
    newComponents.splice(index, 0, newComponent);
    newComponents.map((item, index) => {
      item.positionIndex = index+1;
      return item;
    })
    this.setState({ components: newComponents });
  }

  buildPayload = () => {
    const payload = {
      hiringClientId: this.props.holderId,
      workflowTypeId: this.state.currentWorkflow.id,
      daysBeforeExpirationNotice: this.state.currentWorkflow.DaysBeforeExpirationNotice,
      components: []
    }
    const components = this.state.components;

    for (var i = 0; i < components.length; i++) {
      // create the component
      const component = {
        positionIndex: components[i].positionIndex,
        componentId: _.parseInt(components[i].current_value.componentId),
        parameters: []
      }

      // get components parameters
      const parameters = components[i].current_parameters;
      for (var j = 0; j < parameters.length; j++) {
        const parameter = {
          componentParameterId: parameters[j].componentParameterId,
          value: parameters[j].value
        }
        component.parameters.push(parameter)
      }

      payload.components.push(component);
    }
    return payload;
  }

  buildComponents = (components, possible_values) => {
    for (var i = 0; i < components.length; i++) {
      // look for all possible params for the current components
      let parameters = [];
      for (var j = 0; j < possible_values.length; j++) {
        if (components[i].current_value.componentId.toString() === possible_values[j].id.toString()) {
          components[i].current_value.name = possible_values[j].name;
          parameters = possible_values[j].parameters;
          break;
        }
      }
      components[i].current_parameters = this.buildComponentParams(components[i].current_parameters, parameters);
    }
    return components;
  }

  buildComponentParams = (currentParams, allParameters) => {
    const newParams = [];
    for (var i = 0; i < allParameters.length; i++) {
      // create a new possible param for this component
      const newParam = {
        componentParameterId: allParameters[i].id,
        name: allParameters[i].name,
        value: ""
      }
      //check if there's an stored value
      for (var j = 0; j < currentParams.length; j++) {
        if (currentParams[j].componentParameterId === allParameters[i].id) {
          newParam.value = currentParams[j].value;
          break;
        }
      }
      //add new param
      newParams.push(newParam);
    }
    return newParams;
  }

  renderParameter = (component, parameter, idx, parentId) => {
    const index = _.findIndex(component.current_parameters, function (o) { return o.componentParameterId.toString() === parameter.id.toString() });
    const currentParam = component.current_parameters[index];
    // create the list of options
    const possibleValues = Utils.getOptionsList("Select an option", parameter.possible_values, 'value', 'id', 'value');

    const onType  = (e) =>{
      // This validation is only for Policy Validation -> Days until validation -> Days field
      if(
        (this.state.currentWorkflow && this.state.currentWorkflow.id === CF_POLICY_VALIDATION_PROCESS)
        && (component && component.current_value.componentId == CF_DAYS_UNTIL_VALIDATION)
      ){
        const input = e.target;
        const value = `${e.target.value}${e.key}`;
        const hasSelection = input.selectionStart !== input.selectionEnd;
        if (
          (!/[0-9]/.test(e.key) && e.which !== BACKSPACE_ASCII) || 
          (!hasSelection && (parseInt(value, 10) <= 0 || parseInt(value, 10) > 275)) || 
          (hasSelection && e.key === '0')
        ) {
            e.preventDefault();
        }
        if (_.get(this.state.errors, `${parentId}.${component.current_value.componentId}.${currentParam.componentParameterId}`)) {
          const errors = Object.assign({}, this.state.errors);
          errors[parentId][component.current_value.componentId][currentParam.componentParameterId] = '';
          this.setState({
            ...this.state,
            errors
          })  
        }
      }
    }
    const onParamChange = (e) => {
      // modify the component's current parameter value
      const componentParams = component.current_parameters;
      componentParams[index].value = e.target.value;
      // look for the component and asign the new params
      const newComponents = this.state.components.map(
        (a, index) => a.positionIndex === component.positionIndex ?
          {
            ...a,
            current_parameters: componentParams
          } : a
      );
      let comName = component.current_value.name;
      let showRoleAndIds = false;
      if (comName === "Send Email" || comName === "Add Non-Waiting Task" || comName === "Add Waiting Task") {
        showRoleAndIds = true;
      }
      if (showRoleAndIds && parameter.name.toLowerCase() === 'Assign To Type'.toLowerCase()) {
        if (e.target.value === '1') {
          const tempRoles = [...this.state.showRoles];
          const tempUsers = [...this.state.showUsers];
          const tempDepartments = [...this.state.showDepartments];
          tempRoles.push(parentId);
          const index = tempUsers.indexOf(parentId);
          if (index > -1) {
            tempUsers.splice(index, 1);
          }
          const departmentIndex = tempDepartments.indexOf(parentId);
          if (departmentIndex > -1) {
            tempDepartments.splice(departmentIndex, 1);
          }
          let tempDepartementTags = this.state.departmentTags;
          tempDepartementTags[parentId] = [];
          let tempUserTags = this.state.userTags;
          tempUserTags[parentId] = [];
          this.setState({ showRoles: tempRoles, showUsers: tempUsers, userTags: tempUserTags, showDepartments: tempDepartments, departmentTags: tempDepartementTags });
        } else if (e.target.value === '2') {
          const tempRoles = [...this.state.showRoles];
          const tempUsers = [...this.state.showUsers];
          const tempDepartments = [...this.state.showDepartments];
          tempUsers.push(parentId);
          const index = tempRoles.indexOf(parentId);
          if (index > -1) {
            tempRoles.splice(index, 1);

          }
          const departmentIndex = tempDepartments.indexOf(parentId);
          if (departmentIndex > -1) {
            tempDepartments.splice(departmentIndex, 1);
          }
          let tempDepartementTags = this.state.departmentTags;
          tempDepartementTags[parentId] = [];
          let tempRoleTags = this.state.roleTags;
          tempRoleTags[parentId] = [];
          this.setState({ showRoles: tempRoles, showUsers: tempUsers, roleTags: tempRoleTags, showDepartments: tempDepartments, departmentTags: tempDepartementTags });
        } else if (e.target.value === '3') {
          const tempRoles = [...this.state.showRoles];
          const tempUsers = [...this.state.showUsers];
          const tempDepartments = [...this.state.showDepartments];
          tempDepartments.push(parentId);
          const userIndex = tempUsers.indexOf(parentId);
          if (userIndex > -1) {
            tempUsers.splice(userIndex, 1);
          }
          const roleIndex = tempRoles.indexOf(parentId);
          if (roleIndex > -1) {
            tempRoles.splice(roleIndex, 1);
          }
          let tempRoleTags = this.state.roleTags;
          tempRoleTags[parentId] = [];
          let tempUserTags = this.state.userTags;
          tempUserTags[parentId] = [];
          this.setState({ showRoles: tempRoles, showUsers: tempUsers, userTags: tempUserTags, showDepartments: tempDepartments, });
        } else {
          const tempRoles = [...this.state.showRoles];
          const tempUsers = [...this.state.showUsers];

          let index = tempRoles.indexOf(parentId);
          if (index > -1) {
            tempRoles.splice(index, 1);
          }

          index = tempUsers.indexOf(parentId);
          if (index > -1) {
            tempUsers.splice(index, 1);
          }

          this.setState({ showRoles: tempRoles, showUsers: tempUsers });
        }
      }
      this.setState({ components: newComponents });
      
    }

    if(parameter.name == "To Address"){
      let emailOptionValue = component.current_parameters[0].value
      if(typeof emailOptionValue === 'string'){
        emailOptionValue.split(',')
      }
      if(!emailOptionValue.includes(CF_FROM_EMAIL_ADDRESS.toString())) {
        currentParam.value = ''
        return
      }
    }

    return (
      <div key={idx} className="row parameters-row">
        <div className="col col-lg-3 parameter-col">
          <div className="param-name">{parameter.name}:</div>
        </div>
        <div className="col col-lg-9 parameter-col" style={{ paddingLeft: '15px' }}>
          {
            parameter.possible_values.length > 0 ?
            <div>
              {
                parameter.multiSelect && parameter.multiSelect === true
                  ? <>
                    <Select
                      isMulti={true}
                      name="colors"
                      options={possibleValues.filter(item => item.value !== '')}
                      className="param-select"
                      classNamePrefix="select"
                      isClearable={false}
                        defaultValue={
                          possibleValues.filter(v => {
                            if(v.value !== '') {
                              if(typeof component.current_parameters[index].value === "string"){
                                return component.current_parameters[index].value.split(',').includes(v.value)
                              } else {
                                return component.current_parameters[index].value.includes(v.value)
                              }
                            }
                          })
                        }
                        onChange={(tags) => {
                          const componentParams = component.current_parameters;
                          componentParams[index].value = tags.map((tag) => tag.value);
                          const newComponents = this.state.components.map(
                            (a, index) => a.positionIndex === component.positionIndex ?
                              {
                                ...a,
                                current_parameters: componentParams
                              } : a
                          );
                          this.setState({ components: newComponents });

                        }}
                      // onChange={(tags) => this.handleTagsInput('emailOptionTags', tags, idx, component, component.current_parameters.filter(p => p.name.toLowerCase() === 'Email options'.toLowerCase())[0])}
                      styles={this.state.caseTagsStyles}
                    />
                    </>
                  : <select className="param-select" onChange={onParamChange} value={currentParam.value}>
                    {
                      possibleValues.map((item, idx) => {
                        return (
                          <option key={idx} value={item.value}>{item.label}</option>
                        )
                      })
                    }
                  </select>
              }
              {this.state.errors[parentId] && this.state.errors[parentId][component.current_value.componentId] && this.state.errors[parentId][component.current_value.componentId][currentParam.componentParameterId] ?
                <h6
                  className="text-danger position-absolute"> {this.state.errors[parentId][component.current_value.componentId][currentParam.componentParameterId]}</h6>: null
            }
            </div>
              :
              <div>
                <input className="param-select" type="text" placeholder={(( this.state.currentWorkflow && this.state.currentWorkflow.id === CF_POLICY_VALIDATION_PROCESS) && (component && component.current_value.componentId == CF_DAYS_UNTIL_VALIDATION)) ? '>= 30 AND <= 275' : ''} value={currentParam.value} onKeyDown={onType} onChange={onParamChange} />
                {this.state.errors[parentId] && this.state.errors[parentId][component.current_value.componentId] && this.state.errors[parentId][component.current_value.componentId][currentParam.componentParameterId]   ?
              <h6  className="text-danger position-absolute"> {this.state.errors[parentId][component.current_value.componentId][currentParam.componentParameterId]}</h6>: null
            }
              </div>
          }

        </div>
      </div>
    );
  }

  renderComponent (component, idx) {
    const positionIndex = idx + 1;
    const currentValueId = component.current_value.componentId || "";

    let initialShowUsers = false;
    let initialShowRoles = false;
    let initialShowDepartment=false;

    // TODO: FIX: HACK: Workflows are not designed to receive dynamic data nor form compound fields, so this patch is applied to a feature that adds static capabilities.
    const dynamicParameter = component.current_parameters.find(x => x.name === "Assign to Type")

    if (dynamicParameter && ['1', '2'].includes(dynamicParameter.value)) {
      if (dynamicParameter.value === '2') initialShowUsers = true;
      else initialShowRoles = true; 
    }


    // get all selected component's possible parameters
    let parameters = [];
    
    if (currentValueId !== "") {
     
      const index = _.findIndex(this.state.possible_values,function(o) {return o.id.toString() === currentValueId.toString() });
     
     parameters = this.state.possible_values[index].parameters;
    }
    
    if(component.current_value.componentId === CF_SEND_MAIL ){
      const mailTOAddressParameterIndex = parameters.findIndex((parameter)=>  parameter.id === CF_TO_ADDRESS )
      // Display 'To Address' componentParameter below 'Email Option' componentParameter
      if(mailTOAddressParameterIndex !== 1){
        parameters.splice(1, 0 ,parameters[mailTOAddressParameterIndex] )
        parameters.splice(4)
      }
    }

    // create the list of options
    let possibleValues = Utils.getOptionsList("Select an action", this.state.possible_values, 'name', 'id', 'name');
    let currentComponentName= component.current_value.name;
    const onComponentChange = (e) => {
      // If MailTemplats are not configured, show message to configure it first 
      if(e.target.value === CF_SEND_MAIL.toString() ){
        const sendMailValue = this.state.possible_values.find((possibleValue)=>  possibleValue.id === CF_SEND_MAIL )
        const mailTemplateValues = sendMailValue.parameters.find(sendMailParameters => sendMailParameters.id === CF_MAIL_TEMPLATE  )
        if(mailTemplateValues.possible_values.length === 0) return Swal.fire({
          icon: 'error',
          title: 'Failed to Load Send Email Component',
          text: 'Please configure Mail Tempaltes to use Send Mail component',
        })

      }
      //look for the modified component and change the current_value.componentId
      let tempUserTags=this.state.userTags;
       tempUserTags[idx]=[];
      let tempRoleTags=this.state.roleTags;
      tempRoleTags[idx]=[];
      let tempDepartmentTags=this.state.departmentTags;
      tempDepartmentTags[idx]=[];
      let tempShowUsers=this.state.showUsers;
      
      let tempShowRoles=this.state.showRoles;
      let tempShowDepartments=this.state.showDepartments;

      if (tempShowUsers && tempShowUsers.length > 0) {
        let index = tempShowUsers.indexOf(idx);
        if (index > -1) {
          tempShowUsers.splice(index, 1);
        }
      }
      if (tempShowRoles && tempShowRoles.length > 0) {
        let index = tempShowRoles.indexOf(idx);
        if (index > -1) {
          tempShowRoles.splice(index, 1);
        }
      }
      if (tempShowDepartments && tempShowDepartments.length > 0) {
        let index = tempShowDepartments.indexOf(idx);
        if (index > -1) {
          tempShowDepartments.splice(index, 1);
        }
      }
      this.setState({userTags:tempUserTags,roleTags:tempRoleTags,showUsers:tempShowUsers,showRoles:tempShowRoles,showDepartments:tempShowDepartments,departmentTags:tempDepartmentTags})
      let newComponents = this.state.components;
      for (var i = 0; i < newComponents.length; i++) {
        if (newComponents[i].positionIndex === positionIndex) {
          newComponents[i].current_value.componentId = e.target.value;
          break;
        }
      }
      //build components to get new current_value.name and parameters
      newComponents = this.buildComponents(newComponents, this.state.possible_values);
      // save the modified component list
      this.setState({components: newComponents});
    }

    //VE-7313: Filterirng for Policy Validation Process(WorkflowTypes = 14)
    if (this.state.currentWorkflow && this.state.currentWorkflow.id === CF_POLICY_VALIDATION_PROCESS) {
      const wfActionForPolicyValidationPlaceholderValue = '';
      const wfActionsForPolicyValidation = [wfActionForPolicyValidationPlaceholderValue, CF_ADD_WAITING_TASK, CF_ADD_NON_WAITING_TASK, CF_SEND_MAIL, CF_DAYS_UNTIL_VALIDATION];
      possibleValues = possibleValues.filter(item => wfActionsForPolicyValidation.includes(item.value));
    } else {
      possibleValues = possibleValues.filter(item => item.value !== CF_DAYS_UNTIL_VALIDATION);
    }

    return (
      <div key={idx} className="row component-row">
        <div className="col col-lg-1 cell">
          <span className="component-line-id">{positionIndex}</span>
        </div>
        <div className="col col-lg-3 cell dashed-border-left">
          <select className="component-select" onChange={onComponentChange} value={currentValueId}>
            {
              possibleValues.map((item, idx) => {return (
                <option key={idx} value={item.value}>{item.label}</option>
              )})
            }
          </select>
          {
            this.state.errors[positionIndex - 1] && this.state.errors[positionIndex -1 ].invalidComponent  ?
            <h6  className="text-danger position-absolute mt-5 pt-md-0 pl-2 w-75" > Invalid Component</h6>: null
          }
        </div>
        <div className="col col-lg-5 cell dashed-border-left" style={{ display: 'flex', flexDirection: 'column' }}>
           
        {(initialShowDepartment || this.state.showDepartments.includes(idx)) && <div className="row parameters-row">
          <div className="col col-lg-3 parameter-col">
            <div className="param-name">Assign To Departments:</div>
          </div>
          <div className="col col-lg-9 parameter-col" style={{paddingLeft: '15px'}}>
            <Select 
              isMulti = {currentComponentName === 'Add Waiting Task'? false : true}
              name="colors"
              options={this.state.departmentList}
              className="param-select"
              classNamePrefix="select"
              isClearable={false}
              defaultValue={this.state.departmentTags[idx]}
              onChange={(tags) => this.handleTagsInput('departmentTags', tags, idx, component, parameters.filter(p => p.name.toLowerCase() === 'Assign To Departments'.toLowerCase())[0])}
              styles={this.state.caseTagsStyles}
            />
          </div>
        </div>}
        {(initialShowRoles || this.state.showRoles.includes(idx)) && <div className="row parameters-row">
          <div className="col col-lg-3 parameter-col">
            <div className="param-name">Assign To Role:</div>
          </div>
          <div className="col col-lg-9 parameter-col" style={{paddingLeft: '15px'}}>
            <Select
              isMulti= {currentComponentName === 'Add Waiting Task'? false : true}
              name="colors"
              options={this.state.roles}
              className="param-select"
              classNamePrefix="select"
              isClearable={false}
              defaultValue={this.state.roleTags[idx]}
              onChange={(tags) => this.handleTagsInput('roleTags', tags, idx, component, parameters.filter(p => p.name.toLowerCase() === 'Assign To Role'.toLowerCase())[0])}
              styles={this.state.caseTagsStyles}
            />
          </div>
        </div>}
        {(initialShowUsers || this.state.showUsers.includes(idx)) && <div className="row parameters-row">
          <div className="col col-lg-3 parameter-col">
            <div className="param-name">Assign To ID:</div>
          </div>
          <div className="col col-lg-9 parameter-col" style={{paddingLeft: '15px'}}>
            <AsyncPaginate
              isMulti = {currentComponentName === 'Add Waiting Task'? false : true}
              isSearchable
              openMenuOnClick
              openMenuOnFocus
              closeMenuOnScroll
              loadOptionsOnMenuOpen
              cacheOptions
              pageSize={10}
              additional={{ page: 1 }}
              classNamePrefix="vk"
              className="select-parent"
              menuPlacement="bottom"
              defaultValue={this.state.userTags[idx]}
              getOptionLabel={el => el.value}
              onChange={(tags) => this.handleTagsInput('userTags', tags, idx, component, parameters.filter(p => p.name.toLowerCase() === 'Assign To ID'.toLowerCase())[0])}
              loadOptions={(value,  prevOptions, additional) => loadOptions(value, prevOptions, additional, this.props.holderId)}
            />
          </div>
        </div>}
        { parameters.map((param, index) => {
              if (!((currentComponentName === 'Send Email'||currentComponentName === 'Add Waiting Task'||currentComponentName==='Add Non-Waiting Task') 
              && (param.name.toLowerCase() === 'Assign To Departments'.toLowerCase() ||param.name.toLowerCase() === 'Assign To ID'.toLowerCase() || param.name.toLowerCase() === 'Assign To Role'.toLowerCase()))) {
                return (
                  this.renderParameter(component, param, index, idx)
                )
              }
            }) 
          }
        </div>
        <div className="col col-lg-2 cell dashed-border-left" style={{ paddingLeft: '30px' }}>
          <RolAccess
            masterTab="workflow"
            sectionTab="edit_workflow"
            component={() => this.renderButtonDeleteWorkflowAction(positionIndex)}>
          </RolAccess>
        </div>
      </div>
    );
  }

  renderButtonAddWorkflow() {
    const component = (
      <button className="add-component-btn" onClick={this.onAddComponent}>Add Action</button>
    );
    return component;
  }

  renderButtonEditWorkflow() {
    const component = (
      <ul>
        {/* <li><a
          className="list-view-nav-link nav-bn icon-log no-overlapping"
          onClick={this.onResetWorkflow}>
          {"RESET TO DEFAULT"}
        </a></li> */}
        <li><a
          className="list-view-nav-link nav-bn icon-save no-overlapping"
          onClick={this.onSaveChanges} >
          {"SAVE CHANGES"}
        </a></li>
      </ul>
    );
    return component;
  }

  renderEnabledComponent(item) {
    return (
      <div className="col-md-auto mt-1" style={{display:'flex', alignItems: 'center'}}>            
        <div>
          <input
            type="checkbox"
            checked={item.isEnabled}
            className="pretty-checkbox checkbox-workflow"
            onChange={(e) => this.toggleEnableWorkflow(e, item)}
          />&nbsp;
          <span>Enable</span>
        </div>
      </div>
    )
  }  

  toggleEnableWorkflow = (e, item) => {
    const { checked } = e.target;
    const { title, text, enableTitle, disableTitle, enableText, disableText } = this.props.local.strings.workflows;
    const isEnabledTitle = (checked) ? enableTitle : disableTitle;
    const isEnabledText = (checked) ? enableText : disableText;
    const enable = (checked) ? 1 : 0;

    Swal({
      title: title.replace('$1', isEnabledTitle),
      text: text.replace('$1', isEnabledText).replace('$2', this.props.holderId) ,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        this.props.commonActions.setLoading(true);
        this.props.workflowActions.enableWorkflow({ workflowId: item.workflowId, enable: enable }, (err) => {
          this.props.commonActions.setLoading(false);
          if (err) {
            Swal({
              type: 'error',
              title: title.replace('$1', isEnabledTitle),
              text: 'Failed to change workflow status. Please try again or contact the administrator.',
            });
          } else {
            getWorkFlow(this.props.holderId, this.props.userProfile.authToken, (err, data) => {
              this.setState({ workflows: data })
            });
          }
        });
      }
    });
  }

  renderButtonDeleteWorkflowAction (positionIndex) {
    const { removeWorkflowActionLabel, addWorkflowActionLabel } = this.props.local.strings.workflows;
    const component = (
      <>
        <a onClick={(e) => this.deleteWorkflowAction(e, positionIndex)} className="cell-table-link icon-delete">
          {removeWorkflowActionLabel}
        </a>
        <a onClick={(e) => this.onAddComponentOnSpecificIndex(positionIndex)} className="cell-table-link icon-add">
          {addWorkflowActionLabel}
        </a>
      </>
    );
    return component;
  }

  deleteWorkflowAction = (e, positionIndex) => {
    const {removeWorkflowActionLabel, removeWorkflowActionText} = this.props.local.strings.workflows;
    const {components, currentWorkflow} = this.state;
    Swal({
      title: removeWorkflowActionLabel,
      text: removeWorkflowActionText.replace('$1', currentWorkflow.description),
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#2E5965',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!'
    }).then((result) => {
      if (result.value) {
        //console.log('components:', components);
        const indexToRemove = components.findIndex(e => e.positionIndex === positionIndex);
        if (components[indexToRemove]) {
          const componentId = components[indexToRemove].current_value.componentId;
          if (parseInt(componentId, 10) === ADD_WAITING_TASK_WF_COMPONENT_ID) {
            this.setState({
              stepsToRemoveAssocTasks:
                this.state.stepsToRemoveAssocTasks.concat({
                  workflowId: currentWorkflow.workflowId,
                  stepIndex: positionIndex
                })
            });
          }
        }

        components.splice(indexToRemove, 1);
        const reorderedComponents = components.map((e, i) => {
          e.positionIndex = i + 1;
          return e;
        });

        let tempUserTags = this.state.userTags;
        if (tempUserTags[indexToRemove]) {

          tempUserTags.splice(indexToRemove, 1)
        }
        let tempRoleTags = this.state.roleTags;
        if (tempRoleTags[indexToRemove]) {
          tempRoleTags.splice(indexToRemove, 1)
        }
        let tempDepartmentTags = this.state.departmentTags;
        if (tempDepartmentTags[indexToRemove]) {
          tempDepartmentTags.splice(indexToRemove, 1)
        }
        this.setState({
          userTags: tempUserTags,
          roleTags: tempRoleTags,
          departmentTags: tempDepartmentTags,
          components: reorderedComponents
        })
        this.populateRoleAndUsersValues(reorderedComponents)
      }
    }).then(()=>{
      this.setState({...this.state, errors : {}})
    });
  }

  render() {
    if (!this.state.workflows) {
      return null;
    }

    const workflowsTableMetadata = {
      fields: [
        'description',
        'detail',
        'view',
        'enabled'
      ],
      header: {
        description: 'Type',
        detail: 'Description',
        view: '',
        enabled: ''
      }
    };

    const field = this.state.tableOrderActive;
    const direction = this.state.order[field];
    const orderedLanguagesList = _.orderBy(this.state.workflows, [o => o[field] ? o[field].toLowerCase() : ""], direction);
    const workflowsTableBody = orderedLanguagesList.map((item) => {
      const style = item.workflowId ? {} : { color: 'red' };
      return {
        description: item.description,
        detail: item.detail,
        view: (
          <a className="cell-table-link icon-quick_view" style={style}
            onClick={(e) => { this.onViewWorkflow(item) }}>
            VIEW WORKFLOW
          </a>
        ),
        enabled: (
          <RolAccess
            masterTab="hiring_clients"
            sectionTab="enable_workflow"
            component={() => this.renderEnabledComponent(item)}>
          </RolAccess>         
        )
      };
    });

    const start = (this.state.filter.pageNumber - 1) * 10;
    const end = start + 10;
    const workflowsTableData = {
      fields: workflowsTableMetadata.fields,
      header: workflowsTableMetadata.header,
      body: workflowsTableBody.slice(start, end)
    };
    const paginationSettings = {
      total: this.state.workflows.length,//total elements rendered after search is applied
      itemsPerPage: 10,
      setPageHandler: this.setPageFilter,
      currentPageNumber: this.state.filter.pageNumber,
    };
    return (
      <div className="list-view admin-view-body">
        {
          !this.state.isTableVisible?
            <section className="list-view-header projects-view-header">
              <div className="row" style={{justifyContent: 'space-between'}}>
                <nav className="list-view-nav">
                  <ul>
                    <li><a
                      className="list-view-nav-link nav-bn icon-login-door no-overlapping"
                      onClick={() => this.setState({isTableVisible: true})}>
                      BACK TO WORKFLOWS
                    </a></li>
                  </ul>
                </nav>
                {
                  this.state.currentWorkflow.description === 'Pre-Expiration Process' && (
                    <nav className="list-view-nav">
                      <ul>
                        <li style={{textAlign: 'center', fontWeight: 'bold'}}>
                          Pre Expiration Notice -
                          <p>Days before Expiration Date:
                            <select
                              name="daysBeforeExp"
                              onChange={(e) => {
                                this.setState({
                                  currentWorkflow: {
                                    ...this.state.currentWorkflow,
                                    DaysBeforeExpirationNotice: parseInt(e.target.value)
                                  }
                                })
                              }}
                              defaultValue={this.state.currentWorkflow.DaysBeforeExpirationNotice}
                              style={{width: "50px", marginLeft: "20px"}}
                              placeholder="Please select..."
                            >
                              {
                                [...Array(20).keys()].map((v) => (<option value={v + 1}>{v + 1}</option>))
                              }
                            </select>
                          </p>
                        </li>
                      </ul>
                    </nav>
                  )
                }

                <nav className="list-view-nav" style={{marginRight: '20px'}}>
                  <ul>
                    <li><a
                      className="list-view-nav-link nav-bn icon-save no-overlapping"
                      onClick={this.onSaveChanges}>
                      {"SAVE CHANGES"}
                    </a></li>
                  </ul>
                </nav>
              </div>
            </section> : null
        }

        {
          this.state.isTableVisible ?
            <div className="list-view admin-view-body">
              <PTable
                sorted={true}
                items={workflowsTableData}
                wrapperState={this.state}
                tableOrderActive={this.state.tableOrderActive}
                clickOnColumnHeader={this.clickOnColumnHeader}
                isFetching={this.state.loading}
                customClass='templates-list'
                pagination={paginationSettings}
              />
            </div>:null
        }

        {
          !this.state.isTableVisible?
            <div className="edit-workflow-container">
              <div className="workflow-title">
                {this.state.currentWorkflow.description}
              </div>
              <div className="workflow-components">
                <div className="row component-row">
                  <div className="col col-lg-1 cell">
                  </div>
                  <div className="col col-lg-3 cell dashed-border-left">
                    <span className="component-title">WORKFLOW ACTION</span>
                  </div>
                  <div className="col col-lg-5 cell dashed-border-left">
                    <span className="component-title">ACTION PARAMETERS</span>
                  </div>
                  <div className="col col-lg-2 cell dashed-border-left">
                  </div>
                </div>
                {this.state.components.map(this.renderComponent)}
              </div>
              <div className="add-component-btn-container">
                <button className="add-component-btn" onClick={this.onAddComponent}>Add Action</button>
              </div>
            </div>:null
        }

      </div>
    );
  }

};

const mapStateToProps = (state) => {
  return {
    global: state,
    userProfile: state.login,
    local: state.localization,
    register: state.register,
    roleId: state.login.profile.RoleID,
    users: state.users,
    login: state.login,
    departments: state.departments
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    workflowActions: bindActionCreators(workflowActions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
    departmentsActions: bindActionCreators(departmentsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Workflow);
